import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';

const Navbar = () => {

  return (
    <AppBar position="sticky" style={{ background: "none" }}>
      <StyledToolbar style={{ background: "none" }}>
        <StyledIcon src="/././Assets/logo.png" width="80" height="80" alt="CleanseBeam Logo" className="Shop__icon" />
      </StyledToolbar>
    </AppBar>
  );
};



const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (min-width: 600px) {
    height: 70px;
  }

  @media (min-width: 1080px) {
    height: 80px;
  }
`;

const StyledIcon = styled.img`
  height: 40px;
  width: auto;

  @media (min-width: 600px) {
    height: 50px;
  }

  @media (min-width: 1080px) {
    height: 60px;
  }
`;

export default Navbar;
